<template>
    <div>
        <img :src="require('@/images/guide/1.jpg')" style="width: 100%"/>
        <img :src="require('@/images/guide/2(2).jpg')" style="width: 100%"/>
        <img :src="require('@/images/guide/3(2).jpg')" style="width: 100%"/>
        <img :src="require('@/images/guide/4(2).png')" style="width: 100%"/>
        <img :src="require('@/images/guide/5(2).jpg')" style="width: 100%"/>
    </div>
</template>

<script>
    export default {
        name: "guideIndex"
    }
</script>

<style scoped>

</style>